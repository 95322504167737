import React from 'react';
import { Box, Button, ButtonGroup } from '@chakra-ui/react';

interface Props {
  buttons: {
    key: string;
    label: string;
  }[];
  selected: string;
  onChange: (key: string) => void;
}

const ToggleButtonGroup: React.FC<Props> = ({ buttons, selected, onChange }) => {
  const onClickButton = (key: string) => () => {
    onChange(key);
  };

  const selectedIndex = buttons.findIndex(b => b.key === selected);

  return (
    <ButtonGroup
      boxShadow="0px 2px 7px rgba(0, 0, 0, 0.12)"
      m={2}
      rounded="md"
      position="relative"
      overflow="hidden"
      isAttached
      w="full"
    >
      {buttons.map(({ key, label }) => (
        <Button
          key={`toggle-button-${key}`}
          onClick={onClickButton(key)}
          flex={1}
          fontSize="16px"
          color={key === selected ? 'white' : 'gray.500'}
          zIndex={10}
          bg="transparent"
          border={0}
          h="32px"
          cursor="pointer"
          _focus={{ outline: 'none' }}
          _active={{ outline: 'none' }}
          _hover={key === selected ? { bg: 'transparent' } : { bg: 'gray.100' }}
        >
          {label}
        </Button>
      ))}
      <Box
        position="absolute"
        bg="gray.800"
        top={0}
        h="100%"
        zIndex={1}
        transition="all 250ms ease-out"
        width={`${100 / buttons.length}%`}
        left={`${(100 / buttons.length) * selectedIndex}%`}
      />
    </ButtonGroup>
  );
};

export default ToggleButtonGroup;
